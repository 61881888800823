* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
}
