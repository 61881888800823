@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingProgressAnimation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}