@import '../../../theme/theme.styles.scss';

.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &__link, span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    text-decoration: none;
    color: $color_primary;
    font-size: $font_size_md;
    line-height: $line_height;
    cursor: pointer;

    & svg path {
      fill: $color_primary;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: $visited;

      & svg path {
        fill: $visited;
      }
    }
  }
}