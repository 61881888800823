@import "../../../theme/theme.styles.scss";

.rc-dropdown {
  width: 212px;
  min-height: 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: #FFFFFF;
  box-shadow: 0px 2px 26px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  a {
    text-decoration: none;
  }

  & .container {
    min-width: 200px;
    min-height: 34px;
    display: grid;
    grid-template-columns: 1fr 9fr;
    align-items: center;
    padding: 7px 12px;
    gap: 10px;
    &:hover {
      background:#99C6EB;
      border-radius: 10px;
      cursor: pointer;
        .icons {
          stroke: white;
        }
    }

    & .icons {
      width: 16px;
      height: 16px;
      text-align: center;
      stroke: #6F7C98;
      align-items: center;
      display: flex;
      justify-content: center;

        &__name {
          font-family: 'IBM Plex Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3B4168;
          display: flex;
          align-items: center;
       }
    }
  }
}

.users-list {
  &__table-wrapper {
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 40px $table_shadow;
  }

  & .users-table {
    &__wrapper {
      width: 100%;

      @media (max-width: 1200px) {
        & {
          overflow-x: scroll;
        }
      }
    }

    & [class$="-cell"] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: $font_size_md;
      line-height: 20px;
      color: $secondary;
    }

    &__avatar-cell {
      display: flex;
      justify-content: center;
      gap: 10px;

      &__icon-wrapper {
        width: 32px;
        height: 32px;
      }

      &__fullName {
        font-size: $font_regular;
        line-height: $line_height_sm;
        font-weight: 500;
        margin: 0;
      }

      &__id {
        font-size: $font_size_sm;
        line-height: $line_height_sm;
        margin: 0;
        margin-top: 3px;
      }

      &__copy-icon{
        opacity: 0;
        margin-left: 5px;
      }
    }

    & .table__header {
      display: grid;
      grid-template-columns: 3fr 3fr repeat(5, 150px);
      padding: 0 10px 10px;
    }

    & .table__content-row {
      display: grid;
      grid-template-columns: 3fr 3fr repeat(5, 150px);
      text-decoration: none;
      align-items: center;
      cursor: pointer;
      padding: 10px;
    }

    & .users-table__controls {
      display: flex;
      column-gap: 10px;
    }

    & .users-table__controls-button {
      -webkit-appearance: none;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $background;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;

      & path {
        fill: $secondary;
      }

      &:active {
        background-color: $active;
      }
    }

    & .table__content-column {
      padding: 0;
    }

    & .table__header-column {
      padding: 0;
    }
  }
}

.users-list-empty {
  width: 100%;
  height: 398px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $hover;
  border-radius: 0px 0px 10px 10px;

  &__icon {
    margin-bottom: 20px;
  }

  &__content {
    width: 100%;
    max-width: 333px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__title {
      color: $color_black;
      margin: 0;
      font-size: $font_size_h4;
      line-height: $line_height_h4;
    }

    &__subtitle {
      color: $gloomyBlue;
      margin: 0;
      margin-top: 8px;
      width: 100%;
      max-width: 268px;
      font-size: $font_size;
      line-height: $line_height;
    }
  }
}
