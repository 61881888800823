@import "../../../theme/theme.styles.scss";

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: $background;

  &__title {
    margin: 0;
  }

  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
    background-color: $color_white;
    border-radius: 4px;
  }

  &__form {
    min-width: 300px;
    display: flex;
    row-gap: 10px;
    flex-direction: inherit;
  }

  &__field {
    display: flex;
    flex-direction: column;
  }

  &__error {
    padding: 2px;
    color: $color_danger;
    font-size: 0.8rem;
  }
}
