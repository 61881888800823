.auth {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    box-sizing: border-box;
  }

  &__block {
    color: #0071ce;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    padding: 1rem;
    row-gap: 1rem;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
}