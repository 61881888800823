@import '../../../theme/theme.styles.scss';

.user-codes {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 20px;

  .item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 24px 40px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 40px -15px rgba(33, 36, 55, 0.08);

    .column {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      line-height: 20px;
      
      &:first-child {
        gap: 16px;
      }

      .icon {
        border-radius: 50%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.green {
          background-color: #73D0711A;
          & svg {
            color: black;
          }
        }
        &.blue {
          background-color: #7D6FE11A;
          & svg {
            color: #7D6FE1;
          }
        }
      }

      .title {
        color: #909EBB;
      }
      
      .text {
        color: #3B4168;
        &:first-child {
          font-weight: 500;
        }
      }
    }

    
  }
}