$background: #F5F6F8;
$helsinki: #73AAFF;


$gray: #909EBB;
$active: #99C6EB;
$hover: #F5FBFF;
$lines: #E4E7F2;
$secondary: #6F7C98;
$visited: #7D6FE1;

$lightBlue: #F0F7FC;
$gloomyBlue: #3B4168;
$input: #B7B9C0;
$inputDisabled: #F9F9F9;


$color_disabled: #BDBDBD;
$color_stone: #44403c;
$color_orange: #e26b3c;
$color_black: #12152A;
$color_gray: #727783;
$color_white: #FFFFFF;
$color_primary: #0071CE;
$color_success: #73D071;
$color_danger: #EB5757;
$color_warning: #F7B055;

$gradient_blue_start: #007BE0;
$gradient_blue_end: #0058A0;

$table_shadow: rgba($color: #212437, $alpha: 0.08);

$color_log_dialog_backdrop: rgba(228, 231, 242, 0.4);