.header-profile {
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logout-icon {
    cursor: pointer;
  }

  &__name {
    margin: 0 32px 0 12px;
  }

  &__separator {
    margin: 0 24px;
    width: 1px;
    height: 100%;
  }
}
