@import '../../../theme/theme.styles.scss';

.not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  &__title {
    font-size: $font_size_h2;
    line-height: $line_height_h2;
    color: $color_warning;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  &__link {
    font-size: $font_size_md;
    line-height: $line_height;
    color: $color_primary;

    &:hover {
      color: $active;
    }
  }
}
