@import '../../../theme/theme.styles.scss';

.page-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: wrap row;
  margin: 30px 0;
  font-size: $font_size_h2;
  line-height: $line_height_h2;
  color: $gloomyBlue;
}
