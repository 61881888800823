@import "../../../theme/theme.styles.scss";

.pagination {
  display: flex;
  column-gap: 2.5rem;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.625rem;
    color: gray;
    flex-wrap: wrap-reverse;
  }

  &__page-limit {
    width: 75px;
    display: flex;
    flex-direction: column;
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: $color_primary;

    &__wrapper {
      display: flex;
      align-items: center;
    }

    & .select {
      box-shadow: none;
    }
  }

  &__page-counter {
    display: flex;
    align-items: center;
  }

  &__button {
    padding: 0.25rem;
    cursor: pointer;
    text-transform: none;
    background-color: transparent;
    border: none;

    &_disabled {
      color: $color_disabled;
    }

    &_active {
      color: $color_primary
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
  }
}
