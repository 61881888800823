@import '../../../theme/theme.styles.scss';

.profile {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__iframe {
    display: block;
    border: 2px solid $lines;
    border-radius: 8px;
  }

  &__controls {
    display: flex;
    column-gap: 8px;
    row-gap: 4px;
    align-items: start;
    margin: 16px 0;
    padding: 8px;
    box-sizing: border-box;
    background-color: rgba($color: $lines, $alpha: .5);
    border-radius: 8px;

    &__button {
      &:hover {
        background-color: $color_primary;
        color: $color_white;
      }

      &:active {
        background-color: rgba($color: $color_primary, $alpha: .5);
        color: $color_white;
      }
    }
  }
}
