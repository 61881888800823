@import '../../../theme/theme.styles.scss';

.users-search {
  & .users-search__form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 12px 20px;
    box-sizing: border-box;
    background-color: rgba($color: $lines, $alpha: .5);
    border-radius: 8px;

    &__controls {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      &__button {
        min-width: 192px;
        height: fit-content;
        font-size: $font_size_md;
        font-weight: $font_bold;
        line-height: $line_height;
        padding: 7px 16px;

        &:active {
          opacity: .5;
        }
      }
    }

    &__reset-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $lines;

      &:hover {
        background-color: $color_white;
      }

      &:active {
        background-color: $lines;
        opacity: .5;
      }
    }

    &__inputs {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 8px;
      row-gap: 4px;
      align-items: start;
    }
  }

  &__separator {
    width: 1px;
    height: inherit;
    background-color: $lines;
    margin: 0 20px;
  }

  &__input.input {
    border-radius: 8px;
    padding: 8px 36px 8px 16px;
    font-size: $font_size;
    line-height: $line_height;
    font-weight: $font_regular;
    color: $gloomyBlue;

    &::placeholder {
      color: rgba($color: $gloomyBlue, $alpha: .8);
      font-size: $font_size_sm;
      opacity: 1;
    }
  }

  & .input__hint-text {
    font-size: $font_size_sm;
    padding: 2px 4px 0;
    margin: 0;
  }

  & .input__icon-button {
    padding: 0;
  }

  &__input-icon {
    width: 16px;
    height: 16px;

    &__wrapper {
      padding: 1px 16px 1px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}
