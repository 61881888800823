@import "../../../theme/theme.styles.scss";

.toast {
  box-shadow: 0 0 $color_black, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  background-color: $color_white;
  border-top: 3px solid transparent;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  min-width: 350px;
  width: 350px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  position: relative;

  &_default {
    border-top-color: $color_primary;
  }

  &_warning {
    border-top-color: $color_warning;
  }

  &_error {
    border-top-color: $color_danger;
  }

  &_success {
    border-top-color: $color_success;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  &__dismiss-icon {
    color: $gloomyBlue;
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
  }

  &__text {
    margin: 0;
    white-space: pre-line;
    font-weight: $font_semibold;
    color: $gloomyBlue;
    line-height: 24px;
    font-size: $font_size;
  }

  &__progress {
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: -12px;
    height: 2px;
    transition-duration: 300ms;
    animation-timing-function: ease-in;
    animation-name: loadingProgressAnimation;

    &_default {
      background-color: $color_primary;
    }

    &_warning {
      background-color: $color_warning;
    }

    &_error {
      background-color: $color_danger;
    }

    &_success {
      background-color: $color_success;
    }
  }

}
