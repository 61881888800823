@import ".././../../theme/theme.styles.scss";

.select {
  position: relative;
  box-shadow: 0 0 $color_black, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

  &__wrapper {
    display: flex;
    flex-direction: column;
    outline: none;
    width: 220px;
  }

  &__selected-label {
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    justify-content: space-between;
    align-items: center;
    appearance: none;
    cursor: pointer;
    display: flex;
    position: relative;

    &_error {
      border: 1px solid $color_danger;
      border-radius: $border_radius;
    }

    &_disabled {
      background-color: $lines;
    }

    &_sm {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    &_md {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__dropdown {
    background-color: $color_white;
    border-radius: $border_radius;
    overflow: hidden;
    min-width: 100%;
    z-index: 100;
    position: absolute;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);



    &_dropTop {
      bottom: calc(100% + 10px);
    }

    &_dropBottom {
      top: calc(100% + 10px);
    }

    &_dropLeft {
      left: 0;
    }

    &_dropRight {
      right: 0;
    }

    &_visible {
      visibility: visible;
    }

    &_invisible {
      visibility: hidden;
    }

    &__content {
      overflow-y: auto;
      scroll-padding-bottom: 0.5rem;
      scroll-padding-top: 0.5rem;
      max-height: 300px;
    }
  
    &__search {
      border: 1px solid #E4E7F2;
      color: #3B4168;
      &::placeholder {
        color: #3B4168;
      }
      &__wrapper {
        padding: 8px 8px 8px 16px;
      }
    }
  }


  &__option {
    color: $secondary;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: $hover;
    }
  }

  &__icon {
    cursor: pointer;
    transition-duration: 0.15s;

    &_md {
      right: 1rem;
    }

    &_sm {
      right: 0.25rem;
    }

    &_rotate-180 {
      transform: rotateZ(180deg);
    }
  }

  &__error-text {
    color: $color_danger;
    font-size: $font_size_sm;
    font-weight: $font_regular;
    line-height: 1.25rem;
  }

  &__label {
    color: $secondary;
    font-size: $font_size_sm;
    font-weight: $font_regular;
    margin-bottom: 0.375rem;
    line-height: 1.25rem;
  }
}
