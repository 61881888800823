@import "../../../theme/theme.styles.scss";

.button {
  transition-duration: $transition_duration_fast;
  border: 2px solid transparent;
  border-radius: $border_radius;
  margin: 0;
  font-weight: $font_bold;
  appearance: none;
  outline: none;
  cursor: pointer;

  &_disabled {
    background-color: rgb(243 244 246/1);
    color: rgb(209 213 219/ 1);
    pointer-events: none;
    cursor: default;
  }

  &_none {
    padding: 0;
  }

  &_sm {
    padding: 0.75rem 2rem;
  }

  &_md {
    padding: 1.25rem 3rem;
  }

  &_primary {
    background-color: $color_primary;
    color: $color_white;

    &:hover {
      background-color: darken($color_primary, 5);
    }
  }

  &_success {
    background-color: $color_success;
    color: $color_white;

    &:hover {
      background-color: darken($color_success, 5);
    }
  }

  &_warning {
    background-color: $color_warning;
    color: $color_white;

    &:hover {
      background-color: darken($color_warning, 5);
    }
  }

  &_danger {
    background-color: $color_danger;
    color: $color_white;

    &:hover {
      background-color: darken($color_danger, 5);
    }
  }

  &_outline {
    background-color: transparent;
    border-color: $color_black;
    color: $color_black;

    &:hover {
      background-color: inherit;
      text-decoration: underline;
    }
  }

  &_gray {
    background-color: $color_gray;
    color: $color_white;

    &:hover {
      background-color: darken($color_gray, 5);
    }
  }

  &_white {
    background-color: $color_white;
    color: #000000;
  }

  &_gradient {
    background-size: 200%;
    background-image: linear-gradient(135deg, $gradient_blue_start, $gradient_blue_end, $gradient_blue_start);
    background-color: darken($color_primary, 5);
    color: $color_white;

    &:hover {
      background-position: 100%;
    }
  }

  &_standard {
    background-color: transparent;

    &.button_primary {
      font-weight: 500;
      color: $color_primary;

      &:hover {
        background-color: transparent;
        color: lighten($color_primary, 25);
      }
    }
  }
}
