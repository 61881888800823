@import "../../../theme/theme.styles.scss";

.input {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-width: 1px;
  border-radius: 0.25rem;
  border-style: solid;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1rem;
  width: 100%;

  &_default {
    border-color: $lines;
  }

  &_warning {
    border-color: $color_warning;
  }

  &_error {
    border-color: $color_danger;
  }

  &_success {
    border-color: $color_success;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: auto;
  }

  &__icon-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:hover {
      fill: $active;

      & path {
        fill: $active;
      }
    }

    &:active {
      fill: $visited;

      & path {
        fill: $visited;
      }
    }
  }

  &__hint-text {
    margin: 0;
    margin-top: 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    width: 100%;

    &_default {
      color: $secondary;
    }

    &_warning {
      color: $color_warning;
    }

    &_error {
      color: $color_danger;
    }

    &_success {
      color: $color_success;
    }
  }
}
