$font_size: 14px;
$font_size_md: 16px;
$font_size_sm: 12px;

$font_size_h2: 28px;
$font_size_h3: 24px;
$font_size_h4: 20px;
$font_size_h5: 18px;

$line_height: 20px;
$line_height_sm: 16px;
$line_height_h2: 36px;
$line_height_h3: 28px;
$line_height_h4: 24px;

$font_regular: 400;
$font_bold: 500;
$font_semibold: 600;
