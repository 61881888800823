@import "../../../theme/theme.styles.scss";

.logs-list {
  &__table-wrapper {
    box-sizing: border-box;
    padding: 14px 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 40px $table_shadow;
  }

  & .pagination__wrapper {
    margin-top: 0;
    position: sticky;
    bottom: 0;
    background-color: $color_white;
  }

  & .logs-table {
    &__wrapper {
      width: 100%;
      margin-bottom: 1.625rem;

      @media (max-width: 1410px) {
        & {
          overflow-x: scroll;
        }
      }
    }

    & [class$="-cell"] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: $font_size_md;
      line-height: $line_height;
      color: $gloomyBlue;
    }

    &__ip-cell {
      display: flex;
      justify-content: center;
      gap: 10px;

      &__icon-wrapper {
        background-color: rgba($color: $color_orange, $alpha: 0.1);
        border-radius: 100%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__ip {
        font-size: $font_regular;
        line-height: $line_height_sm;
        font-weight: $font_semibold;
        margin: 0;
      }
    }

    &__event-date {
      color: $secondary;
    }

    &__action__text {
      word-break: break-all;
    }

    &__status-cell {
      display: flex;
      align-items: center;
      justify-content: center;

      &__status {
        border-radius: 8px;
        padding: 2px 8px;
        font-weight: 500;
        font-size: $font_size_md;
        line-height: $line_height;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &--success {
          background-color: rgba($color: $color_success, $alpha: .15);
          color: $color_success;
        }

        &--error {
          background-color: rgba($color: $color_danger, $alpha: .15);
          color: $color_danger;
        }
      }
    }

    & .table__header {
      display: grid;
      padding: 10px 0;
      grid-template-columns: 260px 1fr 100px 180px 120px 150px 180px;
      column-gap: 8px;
    }

    & .table__content-row {
      padding: 10px 0;
      align-items: center;
      display: grid;
      grid-template-columns: 260px 1fr 100px 180px 120px 150px 180px;
      column-gap: 8px;

      &:hover .logs-table__controls-button {
        background-color: $color_primary;

        & path {
          fill: $color_white;
        }
      }
    }

    & .table__content-column {
      padding: 0;
    }

    & .table__header-column {
      padding: 0;
    }

    & .logs-table__controls-button {
      width: 36px;
      height: 36px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $background;
      padding: 0;
      margin: 0;
      cursor: pointer;

      & path {
        fill: $secondary;
      }

      &:hover {
        opacity: .8;
      }

      &:active {
        background-color: $active;
      }
    }
  }

  &__filters-form {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 8px;
    row-gap: 4px;
    align-items: center;
    margin: 16px 0;
    padding: 8px;
    box-sizing: border-box;
    background-color: rgba($color: $lines, $alpha: .5);
    border-radius: 8px;

    & .select {
      border-radius: 6px;

      & .select__dropdown {
        max-width: 340px;
        max-height: 400px;
        overflow-y: auto;
      }

      & .select__dropdown_dropBottom {
        top: calc(100% + 4px);
      }
    }

    & &__field {
      width: 100%;
      height: 40px;
      background-color: $color_white;
      border-radius: 6px;

      & .select__selected-option-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .select__selected-label {
        padding: 8px 16px;
      }
    }

    &__date-field {
      width: 100%;
      height: 40px;
      background-color: $color_white;
      border-radius: 6px;
      padding: 8px 16px;
      outline: 2px solid transparent;
      outline-offset: 2px;
      border: none;
      box-shadow: 0 0 $color_black, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
      font-size: $font_size_md;
      font-family: inherit;
      font-weight: $font_regular;
      line-height: $line_height;
      margin: 0;
      cursor: pointer;
    }

    & .react-datepicker__tab-loop {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__reset {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__reset-button {
    -webkit-appearance: none;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 4px;
    transition-duration: 150ms;
    color: $secondary;
    background-color: $hover;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  @media (max-width: 900px) {
    &__filters-form {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 700px) {
    &__filters-form {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.logs-list-empty {
  width: 100%;
  height: 398px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $hover;
  border-radius: 0px 0px 10px 10px;

  &__icon {
    margin-bottom: 20px;
  }

  &__content {
    width: 100%;
    max-width: 333px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__title {
      color: $color_black;
      margin: 0;
      font-size: $font_size_h4;
      line-height: $line_height_h4;
    }
  }
}
