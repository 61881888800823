.user {
  &__loader__wrapper {
    width: 100%;
    height: 100vh;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    & span {
      margin-top: 20px;
    }
  }

  & .page-title {
    margin: 20px 0 6px;
  }
}