@import "../../../theme/theme.styles.scss";

.organizations {
  &__table-wrapper {
    box-sizing: border-box;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 40px $table_shadow;
  }

  & .organizations-table {
    &__wrapper {
      width: 100%;

      @media (max-width: 1200px) {
        & {
          overflow-x: scroll;
        }
      }
    }

    & [class$="-cell"] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: $font_size_md;
      line-height: 20px;
      color: $secondary;
    }

    &__name-cell {
      display: flex;
      justify-content: center;
      gap: 10px;

      &__icon-wrapper {
        background-color: rgba($color: $color_primary, $alpha: 0.07);
        border-radius: 100%;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__name {
        font-size: $font_regular;
        line-height: $line_height_sm;
        font-weight: 500;
        margin: 0;
      }
    }

    & .table__header {
      display: grid;
      grid-template-columns: 1fr 1fr 300px 140px 220px;
      padding: 0 10px 10px;
    }

    & .table__content-row {
      display: grid;
      grid-template-columns: 1fr 1fr 300px 140px 220px;
      text-decoration: none;
      align-items: center;
      cursor: pointer;
      padding: 10px;
    }

    & .table__content-column {
      padding: 0;
    }

    & .table__header-column {
      padding: 0;
    }
  }
}

.organizations-list-empty {
  width: 100%;
  height: 398px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $hover;
  border-radius: 0px 0px 10px 10px;

  &__icon {
    margin-bottom: 20px;
  }

  &__content {
    width: 100%;
    max-width: 333px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__title {
      color: $color_black;
      margin: 0;
      font-size: $font_size_h4;
      line-height: $line_height_h4;
    }
  }
}
