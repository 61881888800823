@import '../../../theme/theme.styles.scss';

.user-tabs {
  & .rc-tabs-nav-list {
    display: flex;
    padding: 0;
    margin: 0;
    position: relative;
    border-bottom: 2px solid $lines;
    gap: 11px;
  }

  & .rc-tabs-tab {
    padding: 4px;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font_bold;
    font-size: $font_size_h5;
    list-style: $line_height_h4;
    color: $gloomyBlue;
    position: relative;
    bottom: -2px;
    cursor: pointer;

    &:not([class$="-active"]):hover {
      border-bottom-color: $gloomyBlue;
    }

    &-active {
      border-bottom-color: $color_primary;
      color: $color_primary;
    }
  }

  & .rc-tabs-content {
    padding: 0;
  }

  & .rc-tabs-tabpane {
    display: none;

    &-active {
      display: block;
    }
  }

  & .rc-tabs-nav-operations.rc-tabs-nav-operations-hidden {
    height: 0;
  }

  &__tab__label {
    display: flex;
    align-items: center;
    gap: 10px;

    &-icon {
      transition: $transition_duration_regular;

      &:hover {
        transform: rotateZ(90deg);
      }

      &--loading {
        animation: spin .4s linear infinite;
      }
    }
  }
}