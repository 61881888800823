@import ".././../../theme/theme.styles.scss";

.checkboxWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 8px 8px 16px;
  &:hover {
    background-color: $hover;
    cursor: pointer;
  }
}

.checkbox {
  border: 1px solid $lines;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
  &.checked {
    background-color: $color_primary;
  }
}

.checkboxIcon {
  stroke: $color_white;
}

.checkboxLabel {
  color: $gloomyBlue;
}