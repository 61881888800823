@import "../../../theme/theme.styles.scss";

.root-log-dialog {
  & .rc-dialog-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .rc-dialog-mask {
    background: $color_log_dialog_backdrop;
    backdrop-filter: blur(5px);
  }
}

.log-dialog {
  &__content {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      font-size: $font_size_h3;
      line-height: $line_height_h3;
      font-weight: $font_semibold;
      color: $gloomyBlue;
    }
  }

  &.rc-dialog {
    width: 900px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(33, 36, 55, 0.12);
  }

  & .rc-dialog-header {
    padding: 32px 32px 16px;
  }

  & .rc-dialog-title {
    font-size: $font_size_h3;
    line-height: $line_height_h3;
    font-weight: $font_semibold;
    color: $gloomyBlue;
    padding-bottom: 4px;
  }

  & .rc-dialog-close {
    width: 32px;
    height: 32px;
    top: 32px;
    right: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    opacity: 1;

    &:hover path {
      fill: $color_black;
    }
  }

  & .rc-dialog-body {
    padding: 24px 32px 28px;
  }
}

.log-dialog-tabs {

  & .rc-tabs-nav-list {
    display: flex;
    padding: 0;
    margin: 0;
    position: relative;
    border-bottom: 2px solid $lines;
    gap: 11px;
  }

  & .rc-tabs-tab {
    padding: 16px 16px 12px;
    border-bottom: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font_bold;
    font-size: $font_size_h5;
    list-style: $line_height_h4;
    color: $gloomyBlue;
    position: relative;
    bottom: -2px;
    cursor: pointer;

    &:not([class$="-active"]):hover {
      border-bottom-color: $gloomyBlue;
    }

    &-active {
      border-bottom-color: $color_primary;
      color: $color_primary;
    }
  }

  & .rc-tabs-content {
    padding-top: 24px;
    box-sizing: border-box;
  }

  & .rc-tabs-tabpane {
    display: none;

    &-active {
      display: block;
      font-size: 19px;
      line-height: 20px;
      font-weight: 600;
      color: #909EBB;
      background: #FFFFFF;
      overflow: auto;
      padding: 24px;
      border-radius: 8px;
      min-height: 500px;
      width: 100%;
      margin: 0;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      align-items: center;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }

  & .rc-tabs-nav-operations.rc-tabs-nav-operations-hidden {
    height: 0;
  }
}

// react-json-pretty theme styles

.__json-pretty__ {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: $visited;
  background: $color_black;
  overflow: auto;
  padding: 24px;
  border-radius: 8px;
  height: 450px;
  width: 100%;
  margin: 0;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: rgb($color: $color_white, $alpha: 0.05);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb($color: $color_white, $alpha: 0.2);
  }

  & .__json-key__ {
    color: $visited;
  }

  & .__json-value__ {
    color: $color_success;
  }

  & .__json-string__ {
    color: $color_success;
  }

  & .__json-boolean__ {
    color: $color_success;
  }

  .__json-pretty-error__ {
    line-height: 20px;
    color: $color_danger;
    background: #272822;
    overflow: auto;
  }
}
