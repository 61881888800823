@import '../../../theme/theme.styles.scss';

.tooltip {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
  }

  &__icon {
    margin-left: 8px;
  }
}

.rc-tooltip {
  max-width: 181px;

  &-inner {
    background-color: $secondary;
    font-size: $font_size_sm;
    line-height: $line_height_sm;
    color: $color_white;
  }

  &-placement-right .rc-tooltip-arrow {
    border-right-color: $secondary;
  }

  &-placement-left .rc-tooltip-arrow {
    border-left-color: $secondary;
  }

  &-placement-top .rc-tooltip-arrow {
    border-top-color: $secondary;
  }

  &-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: $secondary;
  }
}