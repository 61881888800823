.main {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px 140px;

  @media (max-width: 1890px) {
    & {
      padding: 20px 60px;
    }
  }

  @media (max-width: 1680px) {
    & {
      padding: 20px 40px;
    }
  }

  @media (max-width: 600px) {
    & {
      padding: 10px;
    }
  }
}
