@import "../../../theme/theme.styles.scss";
@import '../../content/users-list/users-list.styles';

.table {
  display: flex;
  flex-direction: column;

  &__header {
    font-weight: $font_regular;
    display: flex;
    color: $secondary;
  }

  &__header-column {
    display: flex;
    flex: 1 1;
    white-space: nowrap;
    padding: 0.75rem;
    color: $secondary;
  }

  &__content-column {
    flex: 1 1;
    display: flex;
    padding: 0.75rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__content-row {
    transition-duration: 0.15s;
    border-bottom-width: 1px;
    cursor: pointer;
    display: flex;
    color: $gloomyBlue;
    border-bottom: 1px solid $lines;

    & .users-table__email-cell__text {
      color: #0071CE;
    }

    & .users-table__controls .users-table__controls-button:nth-child(3) {
      stroke: $color_white;
    }

    &:hover {
      background-color: $hover;
      border-bottom: 1px solid $active;
      & .users-table__avatar-cell__copy-icon{
       opacity: 1;
      }

      & .users-table__controls {
        & .users-table__controls-button {
          background-color: $color_primary;
          & path {
            fill: $color_white;
            stroke: white;
          }
        }
      }
    }
  }
}
