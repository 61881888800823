.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header-wrapper {
    z-index: 99;
    top: 0;
    position: sticky;
  }
}